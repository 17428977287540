.form {
    &.password,
    &.send,
    &.create {
        &.reset,
        &.confirmation,
        &.account {
            min-width: 0;
            width: 100%;
        }
    }
}