.copyright,
.page-footer {
    background-color: $gray;
    color: $dark-gray;
}
.footer.content {
    h3 {
        color: black;
    }
    .links {
        a {
            color: #000;
        }
    }
}
.footer-logos-container {
    float: right;
}