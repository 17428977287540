.visible-desktop {
    @include breakpoint($screen_tablet_max){
        display: none !important;
    }
}
.visible-mobile {
    display: none !important;
    @include breakpoint($screen_tablet_max){
        display: block !important;
    }
}
.columns .column {
    max-width: 100%;
}
.home-slider {
    @include span(12 of 12);
    > a {
        display: none;
        &:first-child {
            display: block;
        }
    }
    &.owl-carousel {
        .owl-item {
            > a {
                display: block;
            }
        }
    }
    &.shorter {
        @include span(9 of 12);
        @include breakpoint($screen_tablet_max){
            @include span(12 of 12);
        }
    }

    img {
        width: 100%;
    }
}


.home-login {

    @include span(last 3 of 12);

    @include breakpoint($screen_tablet_max){
        @include span(12 of 12);
    }

    #home-login-forms {
        background: $gray;
        //opacity: 0;
    }
    .form.form-login,
    .form.create.account {
        fieldset {
            margin: 0;
            .field,
            .fields > .field {
                &,
                &:last-child {
                    margin-bottom: 5px;
                }
            }
        }
        .field {
            label {
                display: none;
            }
        }
    }
    .ui-tabs-nav {
        padding: 0;
        margin: 0;
        display: flex;
        li {
            margin: 0;
            list-style: none;
            display: inline-block;
            vertical-align: top;
            flex: 50% 1 1;
            text-align: center;
            a {
                display: block;
                font-weight: bold;
                color: black;
                padding: 0 25px;
                line-height: 45px;
                text-decoration: none;
                i{
                    font-size: 22px;
                    margin: 10px 0 0;
                }
            }
            background: #d4d4d4;
            &.ui-state-active {
                background: $gray;
            }
        }
    }
    .ui-tabs-panel {
        padding: 12px;
        color: $dark-gray;
        h4 {
            text-align: center;
            font-size: 18/14 * 1em;
            line-height: 20/18em * 1em;
        }
        position: relative;
        z-index: auto;
        .herba-modal-close {
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 18px;
        }
    }
    #home-block-password-box {
        .password-strength-meter {
            height: 22px;
            line-height: 22px;
            font-size: 12/14 * 1em;
        }
    }
    .actions-toolbar {
        button.action.primary {
            margin: 0;
            background: $orange;
            @include ease(0.2s);
            &:hover{
                background: darken($orange, 5%);
            }
        }
    }
    .actions-toolbar {
        .primary {
            clear: both;
            width: 100%;
            margin-top: 10px;
            //padding: 0 15px;
            //@include box-sizing();
        }
        .secondary {
            display: block;
            width: 100%;
            clear: both;
        }
    }
}