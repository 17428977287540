.page-layout-1column {
    .block.widget {
        .products-grid {
            .product-item {
                text-align: center;
            }
        }
    }
}
.products {
    &.list {
        .product-item {
            text-align: center;
        }
    }
}
.product-item-name,
.product.name {
    a,
    > a {
        font-size: 16/14 * 1em;
        line-height: 18/16 * 1em;
        font-weight: 700;
        height: 36/16 * 1em;
        display: block;
        overflow: hidden;
        &:hover {
            text-decoration: none;
        }
    }
}
.products-grid {
    .product-item-inner {
        box-shadow: none;
        border: medium none;
        position: static;
        margin : 0;
        padding: 0;
    }
    .product-item-info {
        border-bottom: 1px solid $gray-border;
        padding-bottom: 25px;
        &:hover,
        &.active {
            box-shadow: none;
            border: medium none;
            padding: 0;
            margin: 0;
            padding-bottom: 25px;
            border-bottom: 1px solid $gray-border;
        }
        a.product-info-link {
            font-weight: 700;
            line-height: 25px;
            margin-top: 0;
            color: black;
            &:hover {
                color: black;
            }
        }
    }
}
.product-item-inner,
.product-item-actions {
    a.tocart,
    button.tocart {
        @extend .btn-buy-style;
    }
    .actions-primary {
        display: block;
        width: 100%;
        + .actions-secondary {
            display: none;
        }
    }
}