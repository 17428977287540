body {
    font-family : "Arial", Helvetica, sans-serif;
}
._keyfocus *:focus,
input:focus:not([disabled]),
textarea:focus:not([disabled]),
select:focus:not([disabled]){
    box-shadow: none;
}
html {
    -webkit-text-size-adjust:100%;
    -ms-text-size-adjust:100%;
    font-kerning:normal;
    font-synthesis:none;
    text-rendering:optimizeLegibility;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}

.ko-dump {
    left: 0;
    max-height: 100vh;
    overflow: scroll;
    position: fixed;
    top: 0;
    z-index: 4444;
}
.home-login,
.page-header {
    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="tel"],
    input[type="search"],
    input[type="number"],
    input[type="datetime"],
    input[type="email"]{
        border: medium none;
        border-radius : 8px;
        line-height: 36px;
        height: 36px;
    }
}

.fieldset {
    > .field > .label,
    > .fields > .field > .label {
        margin : 0 0 4px 0;
    }
}
.action.primary {
    border: medium none;
    background: $green;
    height: 40px;
    line-height: 40px;
    border-radius: 2000px;
    padding: 0 20px;
    &:focus,
    &:active,
    &:hover {
        border: medium none;
    }
}

.cms-index-index {
    .block-products-list {
        padding-top: 20px;
        .block-title {
            display: none;
        }
    }
}
.customer-welcome .action.switch {
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background: $green;
    &:after {
        vertical-align: middle;
    }
    &:hover {
        background: darken($green, 5%);
    }
}
.toolbar-products .field.limiter {
    display: none !important;
}