.page-header {
    .panel.wrapper {
        background : $light-green;
        color: black;
    }
}
.logo {
    margin-top: 10px;
}
.nav-sections {
    background : $green;
}

.block-search {
    .control {
        input {
            line-height: 34px;
            height: 36px;
            border-color: $gray;
            background: $gray;
            &::placeholder {
                color: $dark-gray;
                opacity: 1;
            }
        }
    }
    .action.search {
        line-height: 34px;
        height: 36px;
    }
}
.block-minicart .block-content > .actions > .primary .action.primary {
    padding-top: 0;
    padding-bottom: 0;
}
.minicart-wrapper {
    clear: right;
    margin-left: 0;
    font-weight: 700;
    margin-top : 20px;
    margin-bottom: 20px;
    font-size: 16/14em * 1em;
    .action.showcart {
        line-height: 40px;
        cursor: default;
        &.empty {
            display: none;
        }
        &,
        &.active {
            &:before {
                color: $green;
                opacity: 1;
                line-height: 40px;
                margin-right: 10px;
            }
        }
        .counter-number {
            display: none;
        }
        .counter-label {
            clip: auto;
            overflow: visible;
            position: static;
            width: auto;
            height: auto;
            margin: 0;
        }
        .counter.qty {
            background: transparent;
            color: black;
            padding: 0;
            margin: 0;
            line-height: inherit;
            height: inherit;
            vertical-align: top;
        }
    }
}
#header-go-checkout {
    text-transform: uppercase;
    display: inline-block;
    line-height: 40px;
    padding: 0 35px;
    border-radius: 2000px;
    background: $orange;
    color: white;
    font-size: 16/14em * 1em;
    @include ease(0.2s);
    margin-left: 20px;
    &:hover {
        text-decoration: none;
        background: darken($orange, 5%);
    }
    &.hidden {
        display: none;
    }
}
.header.panel {
    > .header.links {
        font-size: 12/14 * 1em;
        line-height: 22/12 * 1em;
        float: left;
        display: flex;
        width: 100%;
        align-items: center;
        .authorization-link {
            &:after {
                margin: 0;
            }
        }
        > li {
            width: 33.3%;
            margin-left: 0;
            &.welcome {
                text-transform: uppercase;
                line-height: 22 / 14 * 1em;
            }
            > a {
                margin-left: 7px;
                font-weight: bold;
                line-height: 22 / 14 * 1em;
                color: black !important;
            }
        }
        > div {
            font-size: 14px;
            width: 33.3%;
            text-align: center;
            &.align-right {
                width: 66.6%;
                text-align: right;
            }
        }
        .authorization-link a:before,
        #toplink-create-account:before {
            content : " ";
            display: inline-block;
            vertical-align: top;
            height: 16px;
            margin-top: 2px;
            margin-right: 5px;
        }

        .authorization-link {
            a:before {
                width: 13px;
                margin-right: 2px;
                background: url('../images/icon-sprite.png') 0 0 no-repeat transparent;
            }
        }
        #toplink-create-account {
            &:before {
                width: 14px;
                background: url('../images/icon-sprite.png') -13px 0 no-repeat transparent;
            }
        }
    }
    .header-logos-container {
        float: right;
        img,
        p {
            vertical-align: top;
            margin: 0;
        }
    }
}
.minicart-items-wrapper {
    max-height: 320px
}