.navigation {
    background: transparent;
    .level0 {
        margin: 0;
        padding: 0;
        > .level-top {
            display: inline-block;
            vertical-align: top;
            @include ease(0.2s);
            line-height: 16/14 * 1em;
            color: rgba(255, 255, 255, 0.75);
            padding: 15px 0;
            span {
                padding: 0 25px;
                border-left: 1px solid rgba(255, 255, 255, 0.5);
            }
            &:hover,
            &.ui-state-focus {
                color: white;
            }
        }
        &:first-child {
            > .level-top {
                span {
                    border-left: medium none;
                    padding-left: 0;
                }
            }
        }
        &.active,
        &.has-active {
            > .level-top {
                color: white;
                border-bottom: medium none;
            }
        }
    }
}