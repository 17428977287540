// STYLE FOR THE BUY BUTTON
.btn-buy-style {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    border-radius : 200px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 10px;
    @include ease(0.1s, background);
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    &:hover {
        background: white;
        color: $green;
        line-height: 34px;
        border: 3px solid $green;
    }
}
